<template>
    <div>
		<el-container>
			<el-header style="text-align:right; font-size: 12px">
			<el-dropdown>
				<i class="el-icon-setting" style="margin-right: 15px"></i>
				<el-dropdown-menu slot="dropdown">
				<el-dropdown-item>View</el-dropdown-item>
				<el-dropdown-item>Add</el-dropdown-item>
				<el-dropdown-item><el-button @click="signOut" type="text">Гарах</el-button></el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<span><b style="color:gray">{{username}}</b></span>
			</el-header>
			<el-main>
				<div>
					<el-form :model="searchData" ref="date" label-position="top" :inline="true" size="mini">
						<el-form-item prop="start_date" label="Эхлэх огноо">
							<el-date-picker v-model="searchData.start_date" type="datetime" placeholder="Эхлэх огноо" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions1" style="width: 180px">
							</el-date-picker>
						</el-form-item>
						<el-form-item prop="end_date" label="Дуусах огноо">
							<el-date-picker v-model="searchData.end_date" type="datetime" placeholder="Дуусах огноо" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions2" style="width: 180px">
							</el-date-picker>
						</el-form-item>
						<el-form-item :label="whitespace">
							<el-button :loading="!isDataLoading" type="primary" @click="getTransactionHistory()" icon="el-icon-search">Хайх</el-button>
						</el-form-item>
						<el-form-item :label="whitespace">
							<el-button :loading="!isDataLoading" style="background-color:#01723a; color:white" @click="console.log()" icon="el-icon-download">Татаж авах</el-button>
						</el-form-item>
					</el-form>
				</div>
				<el-table
					v-loading="!isDataLoading"
					:data="datas"
					style="width: 100%"
					size="mini"
					border class="mt20"
					>
					<el-table-column
						width="200"
						label="payment_request_id"
						prop="payment_request_id">
					</el-table-column>
					<el-table-column
						width="180"
						label="Amount"
						prop="amount">
					</el-table-column>
					<el-table-column
						width="250"
						label="Description"
						prop="description">
					</el-table-column>
					<el-table-column
						width="120"
						prop="status"
						label="Status">
					</el-table-column>
					<el-table-column
						width="120"
						prop="trace_no"
						label="Trace No">
					</el-table-column>
					<el-table-column
						width="130"
						label="ttl"
						prop="ttl">
					</el-table-column>
				</el-table >
			</el-main>
		</el-container>
    </div>
</template>

<script>
import axios from 'axios'
import {
	signOut, getUserName, getEmail
} from '../auth.js'
export default {
    name: 'Home',
    mounted () {
		this.getUser()

        // var d = new Date();
        // let string = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        // string = "" + string + ""
        // this.searchData.start_date = string
        // this.searchData.end_date = string
        this.getTransactionHistory()
    },
    data () {
		return {
			username: '',

            isDataLoading: true,
			fieldType: [{
				value: 'outlet',
				label: 'Харилцагч'
			}, {
				value: 'driver',
				label: 'Жолооч'
			}],
            datas: [],
			search: '',
			searchData: {
				phone: '',
				order_number: '',
				start_date: null,
				end_date: null,
				complained_type: '',
				complained_to: ''
			},
			whitespace: '\xa0',
			pickerOptions1: {
				disabledDate (time) {
					return time.getTime() > Date.now()
				}
			},
			pickerOptions2: {
				disabledDate (time) {
					return time.getTime() > Date.now()
				}
			},
			date: {
				orderDate1: null,
				orderDate2: null
			},
			isDialogShow: false,
			dialogData: {},
			pageDownloadData: {},
			selectAfterComplaintToData: []
		}
	},
    methods: {
        getTransactionHistory () {
			let self = this
            // let payload = {
            //     "ecommerce_token": "1f220c6516f8462e95e571a150001491",
            //     "start_datetime": this.date.orderDate1,
            //     "end_datetime": this.date.orderDate2
            // }
            var data = JSON.stringify({
                "ecommerce_token": "1f220c6516f8462e95e571a150001491",
                "start_datetime": this.searchData.start_date,
                "end_datetime": this.searchData.end_date
            });

            var config = {
                method: 'post',
                url: 'https://ecom.pass.mn/openapi/v1/ecom/txn_history',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            };

            axios(config).then(function (response) {
                console.log(JSON.stringify(response.data));
                self.datas = response.data.ret.transactions

            }).catch(function (error) {
                console.log(error);
            });
            // axios.post('https://ecom.pass.mn/openapi/v1/ecom/txn_history', payload).then(response => {
            //     this.datas = response.data.ret.transactions
            // }).catch(error => {
            //     console.log('error', error)
            // })
        },
		async signOut () {
			const res = await signOut()
			console.log(res)
		},
		async getUser () {
			let user =  await getUserName()
			this.username = user.username
			this.$root.username = await getUserName()
			this.$root.email = await getEmail()
		}
    }
}
</script>

<style>
 .el-header {
    background-color: #ffffff;
    color: #333;
    line-height: 60px;
  }

  .el-aside {
    color: #333;
  }
</style>